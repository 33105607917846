<template>
  <section id="hero" class="bac-section">
    <v-img
      :height="minHeight"
      :src="require('@/assets/marina2.jpg')"
      class="white--text"
    >
      <v-container class="fill-height px-4 py-3 text-center">
        <v-responsive
          class="d-flex align-center mx-auto pt-1"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-heading
            class="hero-head"
            size="text-h2"
            title="PLYMOUTH MARINA PLANNING MASSIVE CONSTRUCTION"
            weight="bold"
          />
          <div class="my-6"></div>
          <base-subheading
            class="hero-subhead"
            weight="medium"
            title="RESIDENTS ARE CONCERNED"
          />
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        // const height = this.$vuetify.breakpoint.mdAndUp ? '50vw' : '66vh'
        // return `calc(${height} - ${this.$vuetify.application.top}px)`
        return "300px";
      },
    },
  }
</script>

<style lang="scss">
.hero-head {
  display: inline !important;
  background-color: #03045Ecc !important;
  line-height: 95% !important;
  font-size: 3.75rem !important;
}
h1.hero-subhead.font-weight-medium.mb-4.text-left.white--text {
  display: inline !important;
  background-color: #03045Eaa !important;
  line-height: 105% !important;
  font-size: 1.5rem !important;
}
</style>
